<template src="./AfishaByTID.html"></template>

<script>
//import Vuetify from 'vuetify/lib/framework';
//import store from "@/store";


import {mapGetters} from "vuex";

export default {
  name: "AfishaByTID",

  props:{

    tid:    {type: Number, default: 0},
    iframe: {type: Boolean, default: false}, // зависит какие ссылки будут

  },

  data: () => ({


    theater:0,

    events:[],
    events_all:[],
    events_pushka:[],
    events_sold_in:[],


    events_loaded:false,

    default_logo:'',

    view_type: 0,
    filter_type: 1,

    filter_type_menu:[
      {
        id:0,
        title: "Пушкинские",
        icon: "mdi-feather"
      },
      {
        id:1,
        title: "Вся афиша",
        icon: "mdi-list-box-outline"
      },
      {
        id:0,
        title: "Есть места",
        icon: "mdi-seat"
      }
    ],

    view_type_menu:[
      {
        id:0,
        title: "Плитка",
        icon: "mdi-view-grid"
      },
      {
        id:1,
        title: "Список",
        icon: "mdi-view-list"
      },
      {
        id:2,
        title: "Таблица",
        icon: "mdi-format-list-bulleted"
      }
    ],


    eventSelect:{},

    header_event:[
      {
        text:"Дата",
        value:"timedate",
      },
      {
        text:"Название",
        value:"show.name",
      },
      {
        text:"Стоимость",
        value:'prices',
      },
      {
        text:"Свободно",
        value:"free_seat",
      },

      {
        text:"Действия",
        value:'actions',
        sortable:false
      }
    ]
  }),

  created() {

    //console.log(this.tid);1
    //console.log(this.iframe);


    if (this.tid>0){
      this.theater = this.tid;
      this.loadAfishaInetEvents();
    }
    else{

      if (this.$route.params.tid) {
        this.loadAfishaInetEvents();
        console.log('after');
      }
      else{
        console.log('нет театра нет входа')
        return '/none'
      }

    }




  },

  computed:{

    ...mapGetters([
      "getProfileInfo",
      "getColorFrame"
    ]),

  },


  mounted() {
    //console.log('mount afisha');
    this.$emit('onFilterOptionsReady', true);
  },



  methods:{

    getEventByShow(show_id){
      return this.events_all.find(x=>x.show_id==show_id);
    },

    getEventByID(event_id){
      return this.events_all.find(x=>x.id==event_id);
    },

    getFilterTypeArr(){
      return this.filter_type_menu;
    },

    getViewTypeArr(){
      return this.view_type_menu;
    },

    getBuyButtonText(event, isList=false){

      let s;

      if (isList){

        if (event.free_seat==0){
          s = "Полный зал"
        }
        else{
          s = "Цена от "+ event.prices+"<br>Купить";
        }

      }
      else{

        if (event.free_seat==0){
          s = "Все билеты проданы"
        }
        else{
          s = "Купить"
        }
      }



      return s;

    },

    onBuyButton(event){

      //console.log(event);

      if (this.iframe){
        //console.log('iframe link');
        this.$emit('eventPick', event.id, event);
      }
      else{
        console.log('full link');
      }




    },

    onDetailButton(show_id, event){

      //console.log(show_id, event);

      if (this.iframe){
        //console.log('iframe link');
        this.$emit('eventDetailPick', show_id, event);
      }
      else{
        console.log('full link');
      }

    },

    stripslash(str){
      return str.replace(/\\/g, '');
    },


    loadAfishaInetEvents(){

      //console.log('coming 32');

      const post_data = {

        global:{
          format:"raw",
          action:"find",
          module:"event"
        },

        data:{

          mode: {
            target:     "coming",
            date_field: "timedate"
          },

          filter:{
            status: 32
          },
          fields:{
            id:1,
            pushka:1,
            show_id:1,
            timedate:1,
            status:1,
            prices:1,
            price_z1:1,
            price_z2:1,
            price_z3:1,
            price_z4:1,
            price_z5:1,
            free_seat:1,
            lay_id:1,
          },
          param:{
            sort:'timedate',
            desc:false,
            limit:500,

          },
          detail:{
            show_id:{
              fields:{
                id:1,
                name:1,
                age:1,
                genre:1,
                time:1,
                culture_id:1,
                tag_status_id:1
              }
            },
            lay_id:{
              fields:{
                name:1,
                place_id:1
              },
              detail:{
                place_id:{
                  fields:{
                    name:1,
                    address:1
                  }
                }
              }
            }
          }
        }
      };

      this.$store.state.api.findEvents(post_data)

        .then( data =>{
          this.events_all       = data;
          this.events_pushka    = this.events_all.filter(x => x.pushka==1);
          this.events_sold_in   = this.events_all.filter(x => x.free_seat>0);

          this.events = this.events_all;

          //console.log(this.events_pushka);

          //this.eventBundlePacking(data);
          this.events_loaded = true;
          this.$emit("onEventsLoaded");
        })
    },

    onFilterChange(e){

      this.filter_type = e;

      console.log(e);

      switch (e){
        case 1:
          this.events = this.events_all
          break;

        case 0:
          this.events = this.events_pushka
          break;

        case 2:
          this.events = this.events_sold_in
          break;
      }

      //this.$forceUpdate();

    },




    onImgLoadError(e){
      console.log(e);
      e.img = this.defaultLogo;
      this.$forceUpdate();
    },

    urlAfishaAdress(eventID){
      let path = "https://202702.selcdn.ru/zakaz/d/";
      let theater_folder = "E39FFEA32C";
      let theater_afisha = "/show/" + eventID + "/a.jpg";
      let urlLogo = "";
      if(!this.defaultLogo){
        this.defaultLogo = path + theater_folder + "/show/0/a.jpg";
      }
      theater_folder = this.getProfileInfo.playhouse.path;

      urlLogo = path + theater_folder + theater_afisha;
      //console.log(urlLogo);
      return urlLogo;
    },
  }
}



</script>

<style scoped>

.show{
  margin: 25px auto;
  padding: 10px;
  width:600px;
  background-color: #ffec85;
  border-radius: 5px;
  font-size:2rem;
  color:black;
  text-align: center;
  cursor: pointer;
}
.show:hover{

  background-color: #ffbb51;
}

a{
  color:black;
  text-decoration: none;
}
@media(min-width:768px){
  .col-sm-20.col{
    flex: 0 0 20%;
    max-width: 20%;
  }
}
/*add*/
.cardAfisha{
  border-radius: 6px;
  overflow: hidden;
}
.afishaImage{
  height: 360px;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #ccc;
}
.afishaImage a{
  display: block;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
}
.afishaImage a img{
  height: auto;
  width: 100%;
  max-width: 100%;
  /*max-height: 100%;*/
  transition: 0.5s;
}
.afishaImage.shadowImage img{
  opacity: 0.5;
  transition: 0.5s;
}
.afishaImage.shadowImage .linkStoped{
  pointer-events: none;
}

.afishaImage a span{
  display: inline-block;
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%) scale(0);
  padding: 5px 10px;
  border-radius: 3px;
  background: #2196F3;
  color:#fff;
  transition:0.5s;
  box-shadow: 0 0 2px #000;
  font-weight: bold;
  opacity: 0;
}
.afishaImage a:hover span{
  transform:translate(-50%,-50%) scale(1);
  opacity: 1;
}
.afishaImage a span:hover{
  transform:translate(-50%,-50%) scale(1.1);
  opacity: 1;
  box-shadow: 0 0 5px #000;
  transition: 0.5s;
}
/*.afishaImage.shadowImage a span{*/
/*  transform:translate(-50%,-50%) scale(0) !important;*/
/*  opacity: 0 !important;*/
/*}*/
.afishaImage.shadowImage:before{
  content: '';
  display: block;
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 1;
}
.afishaImage a:hover img{
  transition:0.5s;
  transform:scale(1.08);
}
.btn-opacity-low.v-btn{
  color: #000 !important;
}
.btn-opacity-low.v-btn:before{
  opacity: 0.4;
  background-color: #ccc;
}
</style>


