<template src="./ShowBuyByTIDaEID.html"></template>

<script>
//Кирпичи страницы:
import galleryContent from '@/components/brick/ShowBuy/byTIDaSID/galleryContent'
import middleContent from '@/components/brick/ShowBuy/byTIDaSID/middleContent'
import buyBtnLink from '@/components/brick/ShowBuy/byTIDaSID/middleContent/buyBtnLink.vue'
import timetableContent from '@/components/brick/ShowBuy/byTIDaSID/timetableContent'
import topContent from '@/components/brick/ShowBuy/byTIDaSID/topContent'
// import HallContent from '@/components/brick/ShowBuy/byTIDaSID/HallContent'
import PixiHallContent from "@/components/brick/ShowBuy/byTIDaSID/PixiHallContent";
//компоненты страницы



// TODO узнать куда перенести
import '@jambonn/vue-concise-carousel/dist/vue-concise-carousel.css'

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: "ShowBuyByTIDaEID",

  components: {
    galleryContent,
    buyBtnLink,
    middleContent,
    timetableContent,
    topContent,
    // HallContent,
    PixiHallContent
  },
  props:["info"],
  data: () => ({
    outsideIDs:false,
    in_brickKey:0,
    in_topInfo:{},
    in_middleInfo:{},
    in_Gallery:{},
    in_timeTable:{},

    in_hall:{},
    in_HallKey:0,

    showFull:{
      show:{
        loaded:false,
        info:{
          name: "Спектакль",
          age: "0+",
          events:[],
          time: "длительность неопределена",
          loaded: false,
        },
        data:[]
      },
      iPushka:{
        name:             "Имя",
        isFree:           false,
        price:            "нет цены",
        maxPrice:         "нет максимальной",
        isPushkinsCard:   false,
        shortDescription: "Короткое описание",
        description:      "Описание",
        genre:            "Жанр" ,
        persons:          "Актеры и персонал",
        pushkaGallery:    false,
        galleryList:      [],
        galleryIndex:     null,
        loaded:false,

        personsRole:      0,
        personsArr:[],
      },
    },
    id_pack:{
      show_id:    0,
      event_id:   0,
      culture_id: 0,
      theater_id: 0,
    },

    showAfishaImg: "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/show/0/a.jpg",

    order:{
      email:'',
      phone:'',
      fio:'',
      event_id:   0,
      show_id:    0,
      culture_id: 0,
      link: false,
      payment_type: 0,
      seats:[],
      show_name:'Спектакль не выбран',
      total:{
        price:0,
        count:0,
      },
      date: 'Сегодня',
    },
    defaultLogo: "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/show/0/a.jpg",
    theater_folder:["E39FFEA32C","B6700AADEA","A1300C78A0","D81BBC58D4","F4500EBDC8","CE223DFBB5","G0934A7F15","H37ACA7049","J853F18DD3"]
  }),

  created() {
    // console.log('В мероприятии')
    if(!this.outsideIDs){
        if (this.$route.params.sid && this.$route.params.tid){
          this.id_find()
          this.loadEventsByShow();
        }
        else{
          console.log('no sid or tid goto afisha');
          return '/none'
        }
    }
  },
  mounted() {
    // console.log("ShowBuyByTIDaEID mounted")
    if(this.info !== undefined){
      // console.log("ShowBuyByTIDaEID info:")
      // console.log(this.info)
      this.outsideIDs = true;
      this.iframeToLoad(this.info);
    }
  },
  methods:{
    iframeToLoad(IDs){
      this.id_pack.show_id    = IDs.sid;
      this.id_pack.event_id   = IDs.eid;
      this.id_pack.theater_id = IDs.tid;
      this.outsideIDs = true;
      let pr = this.$store.state.api.getEventAdm(IDs.eid);
      pr.then( data =>{
        this.id_pack.show_id = data.event.show_id;
        this.loadEventsByShow();
      })
    },
    id_find(){
      this.id_pack.theater_id= this.$route.params.tid;
      this.id_pack.show_id = this.$route.params.sid;
      this.id_pack.event_id = this.$route.params.eid;
    },
    //Внутренние функции
    keyPush(){//перезапускаем кирпичи
      this.in_brickKey++;
    },
    loadInnerContent(inner,arr,bool,data){//формируем данные для кирпичей
      switch(bool){
        case true:
          arr.forEach((item)=>{
            this[inner][item] = data[item];
          })
          break;
        default:
          this[inner][arr[0]] = data;
      }
    },
    scrollMeTo(refName) {//TODO так как нужный блок в кирпиче - текущая реализация не работает
      let element = this.$refs[refName];
      let top = element.offsetTop;
      window.scrollTo({
        top: top,
        left:0,
        behavior: "smooth"});
    },


    urlAfishaAdress(singleImage,arrImage){//формирование карусели
      this.showFull.iPushka.galleryList.push("https://pro.culture.ru/uploads/"+singleImage);
      if(arrImage.length > 0){
        arrImage.forEach(item=>{
          this.showFull.iPushka.galleryList.push('https://pro.culture.ru/uploads/'+item.name);
        })
        this.showFull.iPushka.pushkaGallery = true;
      }else{
        this.showFull.iPushka.pushkaGallery = false;
      }

      let path = "https://202702.selcdn.ru/zakaz/d/";
      let theater_afisha = "/show/" + this.showFull.show.info.id + "/a.jpg";
      let urlLogo = path + this.theater_folder[this.id_pack.theater_id] + theater_afisha;
      // console.log(urlLogo);
      this.defaultLogo = path + this.theater_folder[this.id_pack.theater_id] + "/show/0/a.jpg";
      switch(true){
        case (this.id_pack.culture_id===0):
          this.showAfishaImg = path + this.theater_folder[this.id_pack.theater_id] + "/show/0/a.jpg"
          break;
        default:
          this.showAfishaImg = urlLogo;
          this.showFull.iPushka.galleryList.unshift(urlLogo);
          this.loadInnerContent("in_Gallery",["galleryList"],false,this.showFull.iPushka.galleryList)
      }
      this.loadInnerContent("in_middleInfo",["showAfishaImg"],false,urlLogo);
      this.keyPush();
      return;
    },

    loadEventsByShow(){//show получаем

      this.showFull.show.loaded = false;
      //TODO if isPushka:0 => show:undefined;
      //если выставить isPushka 0 - мероприятия типа s/1/1 загрузятся,
      //но перестанут работать нормальные мероприятия
      let pr2 = this.$store.state.api.findEventsByShowStatusPushka(this.id_pack.show_id, 32, 1);
      pr2.then( data =>{

        this.showFull.show = data['0'];
        if (this.showFull.show.info.culture_id>0){
          this.id_pack.culture_id = this.showFull.show.info.culture_id;

          this.showFull.show.info.id = parseInt(data['0'].info.id);

          this.loadInnerContent("in_topInfo",["id","name","age","culture_id"],true,data['0'].info);
          this.loadInnerContent("in_middleInfo",["time","age"],true,data['0'].info);

          this.loadInnerContent("in_timeTable",["eidExqlude"],false,true);
          this.loadInnerContent("in_timeTable",["eventTimes"],false,data['0'].data);

          this.showFull.show.loaded = true;

          this.loadPushkaByShow();
          this.keyPush();
        }
      })
    },
    loadPushkaByShow(){//iPushka получаем
      this.showFull.iPushka.loaded = false;

      let pr2 = this.$store.state.api.loadPushkaPro(this.id_pack.culture_id);
      pr2.then( data =>{
        this.showFull.iPushka = data['events'][0];
        //перемещение по полученой структуре
        this.showFull.iPushka.genre = data['events'][0].extraFields.genre ;
        this.showFull.iPushka.persons = data['events'][0].extraFields.persons;
        this.showFull.iPushka.galleryList = [];
        this.showFull.iPushka.loaded = true;

        this.urlAfishaAdress(data['events']['0'].image.name,data['events']['0'].gallery);
        this.loadInnerContent("in_Gallery",["galleryList"],false,this.showFull.iPushka.galleryList)
        this.loadInnerContent("in_middleInfo",["name","genre","isFree","price","maxPrice","isPushkinsCard","shortDescription","description","personsArr"],true,data['events'][0])
        this.loadInnerContent("in_middleInfo",["persons"],false,data['events'][0].extraFields.persons)
        this.showFull.iPushka.loaded = true;
        this.loadInnerContent("in_hall",["show_id","event_id","culture_id","theater"],true,this.id_pack);

        this.keyPush();
      })

    },

  //
  //
  //
    onEventToShowHall(){
      if(this.showFull.show.data.find(el=>el.id==this.id_pack.event_id) === undefined){
        this.id_pack.event_id = 0;
      }
      this.$refs.in_hallPortal.id_stak(this.id_pack);
    }
  }
}



</script>

<style scoped>
  @media(max-width: 768px){

    .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row{
      min-height: auto !important;
    }
    .v-data-table__mobile-table-row .v-data-table__mobile-row:last-child .v-data-table__mobile-row__cell{
      margin-bottom: 0 !important;
    }
  }
  @media(min-width: 769px){

    .fullWidthColumn{
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  @media(min-width: 990px){

    .fullWidthColumn{
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
</style>

