var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{ref:"choiceTicket",staticClass:"mt-0",attrs:{"no-gutters":""}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.flags.eidExqlude),expression:"flags.eidExqlude"}],class:{'mb-5':_vm.flags.defaultHidden},attrs:{"block":""},on:{"click":function($event){_vm.flags.defaultHidden = !_vm.flags.defaultHidden}}},[_vm._v(" "+_vm._s(_vm.flags.defaultHidden ? _vm.texts[0] : _vm.texts[1])+" другие сеансы ")]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.flags.defaultHidden),expression:"!flags.defaultHidden"}],staticClass:"pa-0 py-5 py-sm-5",class:{'fullWidthColumn':!_vm.info.isLocalAuth},attrs:{"cols":"12","sm":"12"}},[_c('v-card',{staticClass:"text-left pa-0 pa-sm-4",style:('color:'+_vm.getColorFrame.detailTableText+'; background-color:'+_vm.getColorFrame.detailTableBg)},[_c('v-col',{staticClass:"text-h4 text-sm-h4 text-center text-sm-left ma-0 pt-0 pb-4 px-0",style:('color'+_vm.getColorFrame.detailTitle)},[_vm._v(" "+_vm._s(_vm.flags.eidExqlude ? _vm.texts[3] : _vm.texts[2])+" ")]),_c('v-data-table',{staticClass:"elevation-1",style:('color:'+_vm.getColorFrame.detailTableText+'; background-color:'+_vm.getColorFrame.detailTableBg),attrs:{"headers":_vm.timeConvert.headers,"items":_vm.timeConvert.events,"items-per-page":50,"light":_vm.getColorFrame.light,"dense":"","item-key":"id","single-select":"","hide-default-header":"","hide-default-footer":""},on:{"click:row":function($event){return _vm.itemSelect($event,$event.id)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',{style:('background:'+_vm.getColorFrame.detailTableBg)},[_c('tr',_vm._l((headers),function(h){return _c('th',{staticClass:"text-center",style:('color:'+_vm.getColorFrame.detailTableTextTitle)},[_c('span',[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:"item",fn:function(ref){
var data = ref.data;
var item = ref.item;
var isMobile = ref.isMobile;
var headers = ref.headers;
return [_c('tr',{class:{ 'v-data-table__mobile-table-row': isMobile },on:{"mouseenter":function($event){return _vm.colorCheck($event,true)},"mouseleave":function($event){return _vm.colorCheck($event,false)}}},_vm._l((item),function(td,key,index){return _c('td',{class:{ 'pa-0 v-data-table__mobile-row': isMobile }},[((key==='timeFull'))?_c('div',{class:{
                                        'v-data-table__mobile-row__header pa-4': isMobile,
                                        'text-center':true,
                                        'flex-grow-1':true
                                      }},[_c('span',{staticClass:"text-body-1"},[_c('b',[_vm._v(_vm._s(td))])])]):(td!=='')?_c('div',{class:{
                                        'v-data-table__mobile-row__cell': isMobile,
                                        'text-center my-0 mb-2 mb-sm-0 pa-2':true,
                                        'light-green darken-1':false,
                                        'flex-grow-1':true}},[(isMobile)?_c('div',{staticClass:"v-data-table__mobile-row__header pr-0"},[_vm._v(" "+_vm._s(headers[index].text)+" ")]):_vm._e(),_c('b',[_vm._v(_vm._s(td.time))]),_c('br'),(td.status === 32)?_c('div',[_c('b',[_vm._v("Цена: ")]),(td.prices == 0)?_c('span',[_vm._v("еще не задана")]):_c('span',[_vm._v("от "+_vm._s(td.prices)+" руб.")]),_c('br'),_c('v-btn',{staticClass:"my-2 red",attrs:{"disabled":td.free_seat<1,"color":_vm.getColorFrame.detailTableBuyReady,"dark":!_vm.getColorFrame.light,"light":_vm.getColorFrame.light,"small":""},on:{"click":function($event){return _vm.onEventClick(td.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-star-outline")]),_vm._v(" Купить ")],1),_c('br'),_c('span',[_vm._v("Свободно: "+_vm._s(td.free_seat)+" шт.")])],1):_c('div',[_c('b',{staticClass:"text--disabled"},[_vm._v("Нет сеансов")])])]):_vm._e()])}),0)]}}]),model:{value:(_vm.timeConvert.eventsSelect),callback:function ($$v) {_vm.$set(_vm.timeConvert, "eventsSelect", $$v)},expression:"timeConvert.eventsSelect"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }