<template src="./IframeMain.html"></template>

<script>

import { mapGetters }     from "vuex";

import Afisha             from "@/components/afisha/AfishaByTID/AfishaByTID"
import PixiHall    from "@/components/brick/PixiHall/PixiHall";
import PixiHallContent    from "@/components/brick/ShowBuy/byTIDaSID/PixiHallContent";

import Show               from "@/components/show/ShowBuyByTIDaSID"
import Event              from "@/components/show/ShowBuyByTIDaEID"
import PaymentUserOrder   from "@/components/brick/PaymentUserOrder/PaymentUserOrder"

import gee                from "@/utils/gee";


export default {
  name: "IframeMain",
  components: {Afisha, PixiHallContent, PixiHall, Show, Event, PaymentUserOrder},

  data: () => ({
    gee: gee,

    mode: "global",

    tid:  0,
    sid:  0,
    eid:  0,
    param: "",


    tab: 0,
    menu_show: false,
    menu_filter_show: false,

    ticket_snackbar_show:     false,
    ticket_snackbar_text:     "Привет",
    ticket_snackbar_timeout:  1500,

    default_hall:false,

    filter_ready:false,

    ticket_info_show: false,

    page_title:"Афиша",

    readyToOrder:false,


    // готовность афиши отдавать данные по ref
    filter_options_afisha_loaded:   false,
    filter_options_pixihall_loaded: false,


    id_pack:{
      show_id:    0,
      event_id:   0,
      culture_id: 0,
      theater_id: 0,
    },

    colors:{ //TODO COLOR SETTINGS TO STORE
      header:{
        menuBack:"#fff",
        menuText:"#575a67",
        appbar:"#fff",
        burger:"#ea2425",
        chips:"#ea2425",//#ffcf9f
        breadcrumbs:"#ea2425",//#ffcf9f
        red:"#ea2425"
      },
      body:{
        bg: "#fff"//grey darken-4
      },
      footer:{
        bg:"#fff",
        text:"#575a67",
        btn:"#ea2425",
        tableBg:"#575a67",
        tableText:"#fff",
        burger:"#ea2425",//#ffcf9f
        footerNav:"#fff",//#1e1e1e
        footerNavText:"#575a67",
        footerNavBorder:"#ea2425",//#ffcf9f
        footerNavActive:"#ea2425",//#ffcf9f
        footerNavBtn:"#ea2425"
      }
    },

    now_event_lay_id:0
  }),

  created() {
    // let url = new URL(window.location.href)
    // let theme = url.searchParams.get('theme')
    // this.changeTheme(theme)
  },

  computed:{

    ...mapGetters([
      "getColorFrame",

      "getLeftMenu",
      "isMenuLoaded",

      "isShowBreadcrumbs",
      "getBreadcrumbsIframe",

      "getProfileInfo",

      "getPageInfo",

      "getUserCart",
      "getUserCartInfo",
      "getOrderIframeResult",

      "getCartTicketByID"
    ]),

  },

  watch:{

    tab(){

      if (this.tab==3){
        //console.log('tab is payment');
        this.$refs.PaymentUserOrder.setPaymentType(0);
      }
    },


  },



  mounted() {
    //console.log('mount');
    this.snakebarColor()
    // это происходит после загрузки в афишу
    // this.selectMode();
  },



  methods:{

    // changeTheme(colorTheme){ //TODO тест принудительной смены темы
    //   this.$store.commit('changeFrameTheme',colorTheme)
    // },

    addOrder(){

      //this.$store.dispatch('addIframeOrder');
      this.$refs.PaymentUserOrder.addOrder();

    },

    // поля пользователя заполнены
    onChangeCheckGood(isGood){
      this.readyToOrder = isGood;
    },

    // переход в корзину
    onToCartClick(){

      this.ticket_info_show = false;

      this.tab = 3;
      this.$store.commit("setBreadcrumbIframeDisable", {id:3, value:false})

    },



    // показывать snackbar с креслом
    onSeatSelected(seat_id){

      let t = this.getCartTicketByID(seat_id);
      let s = t.sector.name + " " + t.data.caption;

      this.ticket_snackbar_text = "Билет: "+ s +" - добавлен";
      this.ticket_snackbar_show = true;

    },


    // просто скажем, что готово и можно данные из ребенка по ref'у брать
    onAfishaFilterOptionsReady(){

      // занести их в свои локальные переменные
      //this.filter_options_afisha = JSON.parse(JSON.stringify(options));

      this.filter_options_afisha_loaded = true;
    },

    onAfishaEventsLoaded(){

      this.selectMode();

    },

    onEventLoaded(value){

      //console.warn('Загрузили данные в PIXI, можно брать ценовые зоны');
      // и прокидывать их переключение.
      this.filter_options_pixihall_loaded = value;

    },

    onAfishaEventDetailPick(show_id, event){

      this.$store.commit("setBreadcrumbIframeDisable", {id:1, value:false})

      //console.log(show_id, event);

      this.tab = 1;
      this.sid = show_id;

      this.page_title = event.show.name;
      this.$vuetify.goTo(0,{duration:0});
      this.$refs.show.loadShow(show_id);

    },

    onShowEventPick(event_id){

      //console.log(event_id);

      let event = this.$refs.afisha.getEventByID(event_id);
      this.onAfishaEventPick(event_id, event);

    },

    onAfishaEventPick(event_id, event){
      let params = (new URL(document.location)).searchParams;
      this.default_hall = params.get("theme") !== "samart";
      this.now_event_lay_id = event.lay_id
      setTimeout(()=> {
        if (this.default_hall) {
            this.$refs.PixiHallContent.onShowLoader()
        } else {
          if (event.lay_id === 28) {
            this.$refs.PixiHall.onShowLoader()
            }else{
              this.$refs.PixiHallContent.onShowLoader()
          }
        }
      },200)

      console.warn(event_id,event)
      //console.log(event_id, event);

      this.$store.commit("setBreadcrumbIframeDisable", {id:1, value:false})
      this.$store.commit("setBreadcrumbIframeDisable", {id:2, value:false})
      this.$store.commit("setBreadcrumbIframeDisable", {id:3, value:true})

      this.filter_options_pixihall_loaded = false;

      this.tab = 2;
      this.eid = event_id;

      this.id_pack.event_id = event_id;
      this.page_title = event.show.name + ' - ' + event.timedate_h.string;

      this.$store.commit("setEvent", event);

      this.$vuetify.goTo(0,{duration:0});




      setTimeout(()=>{
        if(this.default_hall){
          this.$refs.PixiHallContent.id_stak(this.id_pack);
        }else{
          if(event.lay_id === 28){
            this.$refs.PixiHall.id_stak(this.id_pack);
          }else{
            this.$refs.PixiHallContent.id_stak(this.id_pack);
          }
        }
      },100)

      // обновляем и вкладку show
      // да, будет лишнее обновление, если мы с show и выбрали event, но не критично
      this.sid = event.show_id;
      this.$refs.show.loadShow(event.show_id);



    },

    selectMode(){

      this.$store.commit("setBreadcrumbIframeDisable", {id:0, value:false})
      this.$store.commit("setBreadcrumbIframeDisable", {id:1, value:true})
      this.$store.commit("setBreadcrumbIframeDisable", {id:2, value:true})
      this.$store.commit("setBreadcrumbIframeDisable", {id:3, value:true})
      this.$store.commit("setBreadcrumbIframeDisable", {id:4, value:true})

      //console.log('selectt');
      //console.warn(this.$route.params);

      this.tid = this.getProfileInfo.playhouse.id;

      let event = {};

      switch (this.$route.params.mode){

        case "afisha":
          this.mode = "afisha";
          break;

        case "show":
          this.mode = "show";
          this.sid = this.$route.params.id;

          // берем первое попавшееся мероприятие с этим show - костылик, да
          event = this.$refs.afisha.getEventByShow(this.sid);
          this.onAfishaEventDetailPick(this.sid, event);

          break;

        case "event":
          this.mode = "event";
          this.eid = this.$route.params.id;
          event = this.$refs.afisha.getEventByID(this.eid);
          this.onAfishaEventPick(this.eid, event);
          break;

        default:
          this.mode = "global";
          break;

      }

    },

    // onPathToPriceFilter(){
    //   $refs.PixiHallContent.seatRaw.getPricesColors
    //   $refs.PixiHallContent.updateMode(item.id)
    // }

    snakebarColor(){
      if(this.getColorFrame.light){
        setTimeout(()=>{
          let content = document.getElementById("alertSnakebar")
          let snakeBody = content.getElementsByClassName("v-snack__wrapper")
          snakeBody[0].setAttribute('style','display:none;border-color:'+this.getColorFrame.snakeThemeIcon+'!important;background-color:'+this.getColorFrame.snakeTheme);
        },200)
      }
    }
  }
}



</script>

<style scoped>

.show{
  margin: 25px auto;
  padding: 10px;
  width:600px;
  background-color: #ffec85;
  border-radius: 5px;
  font-size:2rem;
  color:black;
  text-align: center;
  cursor: pointer;
}
.show:hover{

  background-color: #ffbb51;
}

a{
  color:black;
  text-decoration: none;
}
@media(min-width:768px){
  .col-sm-20.col{
    flex: 0 0 20%;
    max-width: 20%;
  }
}
/*add*/
.cardAfisha{
  border-radius: 6px;
  overflow: hidden;
}
.afishaImage{
  height: 360px;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #ccc;
}
.afishaImage a{
  display: block;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
}
.afishaImage a img{
  height: auto;
  width: 100%;
  max-width: 100%;
  /*max-height: 100%;*/
  transition: 0.5s;
}
.afishaImage.shadowImage img{
  opacity: 0.5;
  transition: 0.5s;
}
.afishaImage.shadowImage .linkStoped{
  pointer-events: none;
}

.afishaImage a span{
  display: inline-block;
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%) scale(0);
  padding: 5px 10px;
  border-radius: 3px;
  background: #2196F3;
  color:#fff;
  transition:0.5s;
  box-shadow: 0 0 2px #000;
  font-weight: bold;
  opacity: 0;
}
.afishaImage a:hover span{
  transform:translate(-50%,-50%) scale(1);
  opacity: 1;
}
.afishaImage a span:hover{
  transform:translate(-50%,-50%) scale(1.1);
  opacity: 1;
  box-shadow: 0 0 5px #000;
  transition: 0.5s;
}
/*.afishaImage.shadowImage a span{*/
/*  transform:translate(-50%,-50%) scale(0) !important;*/
/*  opacity: 0 !important;*/
/*}*/
.afishaImage.shadowImage:before{
  content: '';
  display: block;
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 1;
}
.afishaImage a:hover img{
  transition:0.5s;
  transform:scale(1.08);
}
</style>
<!--
computed:{
    ...mapGetters(["getColorFrame"]),
    }
  bg <=>

  ---header.menuBack
  ---header.appbar
  ---header.tableText
  ---header.footerNav

  btn <=>

  ---footer.footerNavBorder
  ---footer.footerNavActive
  ---footer.footerNavBtn
  ---header.chips
  ---header.breadcrumbs
  ---header.red
  ---base.burger


  text <=>

  ---header.menuText
  ---body.border
  ---footer.footerNavText
  ---footer.tableBg

  btnAlternative <=> btnAlt
-->