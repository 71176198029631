<template>
  <div cols="12" id="pixiDev" @mousewheel.prevent="scroll" class="rounded-0"></div>
</template>

<script>
// import new_hallDBData from "@/assets/json/new_HallData.json"
import { Renderer, Texture, Sprite, Text, TextStyle,BaseTexture,Rectangle,Container } from 'pixi.js';
import { Viewport } from 'pixi-viewport'
import { Graphics } from "@pixi/graphics";
import {Ticker} from "@pixi/ticker";
import {mapGetters} from "vuex";
import _ from "lodash";

export default {
  name: "pixiEditorHall",
  components:[Renderer,Texture,Sprite,Text,TextStyle,Viewport,Graphics,BaseTexture,Rectangle,Container],
  props: {
    dataHall:Object
  },
  data: () =>({
    db_data:{},
    realHall:{},
    realHallFull:{},
    collisionIDs:[],
    flags:{
      componentCreate:false,
      viewportLoaded:false,
      baseCreated:false,
    },
    base: {
      ticker: new Ticker,
      app: null,
      viewport:null,
      loader:null,
      border:null,
      container:null
    },
    chairsData:{
      interactive:[],
      chairs:[],
      selectedOne: {},
      selected:[],
      zoneColors: [0x000000,0xF44336,0x2196F3],
    },
    spritesTexture: {
      preloader: Texture.from('/img/preloader1.png'),
      background: null,
      sprite: null
    },
    // zoneColors: [0x000000,0xF44336,0x2196F3],

    db_word:[]
  }),
  computed: {
    ...mapGetters([
      "getProfileInfo",
      "getSeatColorPixiArr"
    ]),
  },
  mounted() {
    this.$emit('startHallAction')
    this.zoneColors = this.getSeatColorPixiArr;
    this.chairsData.zoneColors = this.getSeatColorPixiArr;
  },
  methods:{
    dataHallRecord(dataHall,seatRaw){
      this.realHall = dataHall
      this.realHallFull = seatRaw
    },
    stepOne_sizeCanvas(dataHall,seatRaw){
      this.dataHallRecord(dataHall,seatRaw)
      let _ww = document.getElementById('pixiDev').offsetWidth;
      let _hh = window.innerHeight;

      if(_ww > 0 && _hh > 0){
        this.stepTwo_canvasCreated(_ww,_hh)
      }

    },
    stepTwo_canvasCreated(_ww,_hh){
      this.$emit('eventloaded', true);
      this.appPrint(_ww,_hh)
      // this.viewportPrint("create",this.db_data.bg.size[0],this.db_data.bg.size[1])
      this.viewportPrint("create",this.realHall.layout.bg.size[0],this.realHall.layout.bg.size[1])
      window.onresize = () => {
        this.sizeControl()
      }
      this.sizeControl()
      this.update()

      this.flags.viewportLoaded = true;
      this.flags.baseCreated = true;
    },
    // Очистка viewport от кресел/фона/border
    appClear() {
      this.chairsData.interactive.splice(0)
      this.chairsData.chairs.splice(0)
      if(this.base.container) this.base.container.children.splice(0)
    },

    //Очистка выбранных кресел, с очисткой их за пределами PixiHall/локально снят выбор
    clearSelected(type) {
      switch (type) {
        case "all":
          //Сценарий только для стартовой отрисовки, так как убивает еще и интерактивные кресла текущего зала
          this.chairsData.selected.splice(0, this.chairsData.selected.length);
          this.chairsData.interactive.splice(0, this.chairsData.interactive.length)
          this.$emit('seatToOrder', this.chairsData.selected);
          break;
        case "pixi":
          //Очищает только выбранные кресла внутри Pixi но не в других компонентах,
          //сценарий на случай, если из других мест выбранные кресла убиваются другими функциями
          this.chairsData.selected.forEach((item) => {
            let finded = this.chairsData.interactive.find(el => el.id === item.id)
            finded.alpha = 0.9;
            finded.scale.x -= 0.05;
            finded.scale.y -= 0.05;
            finded.tint = finded.originalColor;
          })
          this.chairsData.selected.splice(0, this.chairsData.selected.length);
          break;
      }
    },


    reSelectEvent(){
      if(this.base.viewport.children[1])this.base.viewport.children[1].alpha = 0
      if(this.base.viewport.children[2])this.base.viewport.children[2].alpha = 0
      this.appClear();
    },

    startToPrint(type){
      let _ww = document.getElementById('pixiDev').offsetWidth;
      let _hh = document.getElementById('pixiDev').offsetHeight - 100;
      if(_ww !== 0 || _hh !== 0){
        switch(this.flags.baseCreated){
          case true:
            switch(type) {
              case "pre":
                console.log("pre - под вопросом необходимости теперь")
              break;
              case "reCreate":
                if(this.base.viewport.children[1])this.base.viewport.children[1].alpha = 0
                if(this.base.viewport.children[2])this.base.viewport.children[2].alpha = 0
                this.sizeControl();
                this.appClear();
                break;
            }
            break;
        }
        switch(type){
          case "create":
            if(this.event_raw.b && this.flags.viewportLoaded){
              setTimeout(() => {
                this.seatsPrint();
              }, 500)
            }
            break;
          case "reCreate":
            if(this.flags.baseCreated && this.flags.viewportLoaded){
              this.clearSelected("all")
              this.appClear();
            }
            if (this.flags.viewportLoaded) {
              setTimeout(() => {
                this.seatsPrint("reCreate");
              }, 500)
            }
            break;
        }
      }
    },

    rotateAnimation() {
      this.base.loader.rotation += 0.04;
      this.base.app.render(this.base.viewport)
    },
    preLoader(_ww,_hh){
      this.base.loader = new Sprite(this.spritesTexture.preloader)

      this.base.loader.anchor.set(0.5);
      this.base.loader.width = 256;
      this.base.loader.height = 256;
      this.base.loader.x = _ww/2;
      this.base.loader.y = _hh/2;
      this.base.loader.alpha = 0;
      this.base.loader.id = 'loader';

      this.base.ticker.add(this.rotateAnimation)
      this.base.ticker.start();
      this.base.viewport.addChild(this.base.loader);
    },

    getListIdx(str, substr) {
      let listIdx = []
      let lastIndex = -1
      while ((lastIndex = str.indexOf(substr, lastIndex + 1)) !== -1) {
        listIdx.push(lastIndex)
      }
      return listIdx
    },
    compareNumbers(a, b) {
      return a - b;
    },
    decodeSVG(svgCode){
      let svgDecode = {
        allIndex:[],
        letterIndex:[],
        result:[]
      }

      let letters = ["M","L","H","V","C","S","Q","T","A","Z"]

      letters.forEach(el=>{
        let res = this.getListIdx(svgCode, el)
        if(res.length > 0) {
          let resValue = {name: el, indexes: res,values:[]}
          svgDecode.letterIndex.push(resValue)
        }
      })
      svgDecode.letterIndex.forEach(el=>{
        el.indexes.forEach(index=>svgDecode.allIndex.push(index))
      })

      svgDecode.allIndex.sort(this.compareNumbers)

      for(let i = 0;i<svgDecode.allIndex.length;i++){
        let value = svgCode.substr(svgDecode.allIndex[i]+1, svgDecode.allIndex[i+1]-svgDecode.allIndex[i]-1)
        let nameFound = svgDecode.letterIndex.find(el=>el.indexes.find(index => index === svgDecode.allIndex[i]))

        value = value.split(" ")
        let numbers = []
        value.forEach(el=>{numbers.push(Number(el))})

        if(i===0){
          svgDecode.result.push({letter:"M",value:numbers})
        }else{
          svgDecode.result.push({letter:nameFound.name,value:numbers})
        }
      }

      return svgDecode.result;
    },

    graphicsChairPrint(chair){

      if(!chair.graphic || !chair.isFree) return;

      let graphic = this.decodeSVG(chair.graphic)
      let t = new Graphics();

      t.beginFill(0xD9D9D9);
      t.alpha = 0.5;

      graphic.forEach(el=>{
        switch(el.letter){
          case "M":
            t.moveTo(el.value[0],el.value[1])
          break;
          case "C":
            t.bezierCurveTo(el.value[0],el.value[1],el.value[2],el.value[3],el.value[4],el.value[5]);
          break;
          case "Z":
          break;
          case "V":
            // console.log(el)
            // console.log("ряд "+ chair.row + ' место ' + chair.chair)
          break;
          case "L":
            t.lineTo(el.value[0],el.value[1])
          break;
          default:
            console.warn("Найдена векторная команда без сценария")
            console.warn(el)
        }
      })

      t.position.x = chair.coords[0];
      t.position.y = chair.coords[1];

      t.id = chair.id;
      t.alpha = 0.9;
      t.x = chair.coords[0];
      t.y = chair.coords[1];

      t.interactive = chair.isFree;
      t.zone = chair.zone;
      t.price = this.priceFind(chair.zone);
      t.tint = this.chairsData.zoneColors[chair.zone]
      t.originalColor = this.chairsData.zoneColors[chair.zone];
      t.typeSprite="chair"

      t.seatData = {x: t.x, y: t.y,width:t.width,height:t.height, row: chair.row, chair: chair.chair, price: t.price}
      t.on('pointerover', () => {
        let checkStat = this.checkerSeat(t.id);
        switch (checkStat[0]) {
          case false:
            this.textInfoData(true, t.seatData)
            t.alpha = 0.7;
            break;
        }
      })
      t.on("pointerout", () => {
        let checkStat = this.checkerSeat(t.id);
        switch (checkStat[0]) {
          case false:
            this.textInfoData(false)
            t.alpha = 0.9;
          break;
        }
      })
      t.on('pointerdown', () => {
        this.SeatClickTouch(t, "pointerdown")
        this.textInfoData(false)
      });
      if (t.interactive) {
        this.chairsData.interactive.push(t)
      }
      this.chairsData.chairs.push(t);
      this.base.container.addChild(t)



    },

    priceFind(zone) {
      return this.realHallFull.data.prices.find(el => el.zone === zone).price;
    },

    seatsContainerCreate(){
      let checkToContainer = this.base.viewport.children.find(el=>el.id === "seatsContainer")
      if(checkToContainer) return;
      this.base.container = new Container();
      this.base.container.id = "seatsContainer"

      this.base.viewport.addChild(this.base.container)
    },
    seatsPrint(){
      // console.warn(this.base.viewport.children)
      // this.backgroundPrint()
      this.seatsContainerCreate()
      if(!this.realHall) return;


      setTimeout(()=>{
        this.realHall.seat_positions.forEach((el)=>{
          this.graphicsChairPrint(el)
        })
        console.warn(_.union(this.db_word))
        this.textInfo();

        this.sizeControl("afterCreate")
        this.$emit('onReadyToShow')
        if(this.base.viewport.children[1])this.base.viewport.children[1].alpha = 1
        if(this.base.viewport.children[2])this.base.viewport.children[2].alpha = 1
        // setTimeout(()=>{
        //   this.searchCollisions()
        // },1000)
      },500)
    },
    //Создание текстовой подсказки
    textInfo() {
      let style = new TextStyle({
        fontFamily: 'Arial',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fill: '#ffffff',
        wordWrap: true,
        wordWrapWidth: 180,
        lineJoin: 'round',
      });

      let {textInfo, textBack} = this.base;
      textInfo = new Text('', style);
      textInfo.id = 'seatText';

      textBack = new Graphics();

      textBack.lineStyle(2, 0xCCCCCC, 0.25);
      textBack.beginFill(0x000000);
      textBack.drawRoundedRect(0, 0, 180, 44, 16);
      textBack.alpha = 0;
      textBack.endFill();
      textBack.id = "textBack";

      this.base.viewport.addChild(textBack);
      this.base.viewport.addChild(textInfo);
    },
    //Изменение текстовой подсказки
    textInfoData(flag,data){
      let seat = this.base.viewport.children.find(el=>el.id === "seatText");
      let seatBack = this.base.viewport.children.find(el=>el.id === "textBack");
      switch(flag){
        case true:

          seat.text = data.row + " ряд, " + data.chair + " место. \nЦена: " + data.price + " руб.";
          seat.alpha = 1;
          seat.x = data.x + data.width /2;
          seat.y = data.y - 35;
          seat.anchor.set(0.5);

          seatBack.alpha = 0.6;
          seatBack.x = data.x - seatBack.width / 2 + data.width /2;
          seatBack.y = data.y - 56;
          break;
        case false:
          seat.alpha = 0;
          seatBack.alpha = 0;
          seat.text = '';
          break;
      }
    },
    SeatClickTouch(t, eventType) {
      let last_seat = {
        id: t.id,
        selected: false, // было выделено, или снято
      };
      // console.warn(t)
      //определяем какой фильтр к креслу применить в зависимости от ситуации на текущий момент
      let seatInfo = {id: t.id, price: t.price, zone: t.zone}
      let checkStat = this.checkerSeat(t.id);

      switch (eventType) {
        case "pointerdown":
          console.warn(checkStat[0],checkStat[1],checkStat[2])
          switch (checkStat[0]) {
            case true:
              this.chairsData.selected.splice(checkStat[2], 1);
              t.tint = t.originalColor;
              t.alpha = 0.9;
            break;
            default:
              this.chairsData.selected.push(seatInfo);
              // t.tint = 0xffd700;
              t.tint = 0xffffff;
              t.alpha = 1;
              last_seat.selected = true;
          }
          this.$emit('seatToOrder', this.chairsData.selected, last_seat);
        break;
      }
      // console.warn("this.chairsData.selected")
      // console.warn(this.chairsData.selected)
    },

    //проверка выделенности кресла
    checkerSeat(id) {
      //проверка выбранных мест, что бы не перебивать эффект выделения
      // let searchActiveSeat = this.chairsData.selected === id;
      let searchActiveSeat = this.chairsData.selected.findIndex(el => el.id === id);
      let selected = [false, id, searchActiveSeat];

      switch (searchActiveSeat) {
        case -1:
          selected[0] = false;
        break;
        default:
          selected[0] = true;
      }
      return selected;
    },

//Подсветка ценовых зон
    seatColorZone(data) {
      if (data === "all") {
        this.chairsData.interactive.forEach((item) => {
          let selected = this.chairsData.selected.find(el => el.id === item.id);
          if (selected) {
            return;
          }
          item.tint = this.chairsData.zoneColors[Number(item.zone)]
        })
      } else {
        this.chairsData.interactive.forEach((item) => {
          let selected = this.chairsData.selected.find(el => el.id === item.id);
          if (selected) {
            return;
          }
          if (item.zone !== data) {
            item.tint = 0x333333;
          } else {
            item.tint = this.chairsData.zoneColors[Number(item.zone)]
          }
        })
      }
    },

    //Отрисовка бекграунда зала
    backgroundPrint() {
      let checkToBack = this.base.viewport.children.find(el=>el.id === "background")
      if(checkToBack) return;


      console.warn("После обновления опять попали сюда!")
      // this.spritesTexture.background = Texture.from(this.realHall.layout.bg.path+"/"+this.realHall.layout.id+"/"+this.realHall.layout.bg.image)
      this.spritesTexture.background = Texture.from("https://202702.selcdn.ru/zakaz/d/E39FFEA32C/img/zal/28in.jpg")
      let back = new Sprite(this.spritesTexture.background)

      back.id = 'background';
      back.anchor.set(0); //документация https://pixijs.download/dev/docs/PIXI.Sprite.html
      back.x = 0;
      back.y = 0;
      back.interactive = false;

      this.base.viewport.addChild(back)
    },
    update() {

      if (this.base.viewport.dirty) {
        this.base.app.render(this.base.viewport)
        this.base.viewport.dirty = false
      }
      requestAnimationFrame(() => this.update())
    },
    sizeControl(type,_ww,_hh){
      switch(type){
        case "data_raw":
          // console.log("coordinate sizeHall")
          // console.log(type + "/" + _ww + "/" + _hh)
        break;
        case "afterCreate":
          _ww = document.getElementById('pixiDev').offsetWidth;
          _hh = document.getElementById('pixiHallContent').offsetHeight - 100;
        break;
        default:
          _ww = document.getElementById('pixiDev').offsetWidth;
          _hh = document.getElementById('pixiDev').offsetHeight - 100;
          if(document.getElementById('pixiHallContent').offsetHeight - 100 > 200){
            _hh = document.getElementById('pixiHallContent').offsetHeight - 100;
          }
      }

      this.base.app.resize(_ww, _hh)
      this.base.viewport.resize(_ww, _hh)

      this.containerToCenter()
    },
    //Центрирование
    containerToCenter(){
      this.base.viewport.fit();
      this.base.viewport.moveCenter(this.realHall.layout.bg.size[0] / 2, this.realHall.layout.bg.size[1] / 2)
    },
    appPrint(_ww,_hh){
      this.base.app = new Renderer({
        antialias: true,
        autoDensity: true,
        width: _ww,
        height: _hh,
        backgroundAlpha: 0,
        resolution: window.devicePixelRatio || 1,
      });
      document.getElementById('pixiDev').appendChild(this.base.app.view);
      this.flags.baseCreated = true;
    },
    //create viewport
    viewportPrint(type,_ww,_hh){
      this.base.viewport = new Viewport({
        //https://davidfig.github.io/pixi-viewport/ - возможные опции с комментариями
        worldWidth: _ww,
        worldHeight: _hh,
        passiveWheel: false,
        interaction: this.base.app.plugins.interaction,
        disableOnContextMenu: true,
      });

      this.base.viewport
          .drag()
          .pinch({
            noDrag: true,
          })
          .wheel()
          .decelerate()
      // .clampZoom({
      //   minScale: 1.5,                 // minimum scale
      //   maxScale: 1.9,                 // minimum scale
      // })

      this.preLoader(_ww,_hh);

      this.base.viewport.fit()
      this.base.viewport.moveCenter(_ww / 2, _hh / 2)
      this.backgroundPrint() //При условии что зал по фото только один, если добавятся еще - пересмотреть метсо отрисовки


    },
    //Блокировка прокрутки страницы при зуме колесиком
    scroll(e) {
      e = e || window.event;
      if (e.preventDefault)
        e.preventDefault();
      e.returnValue = false;
    },




  }
}
</script>

<style scoped>

</style>
