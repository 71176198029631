<template src="./galleryContent.html"></template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import {Carousel, Slide} from "@jambonn/vue-concise-carousel";
import {mapGetters} from "vuex";
export default {
  components:{
    CoolLightBox,Carousel,Slide
  },
  name: "galleryContent",
  props: ["info","id","pictures_count"],
  data: ()=>({
    infoLoaded:false,
    //CoolLightBox
    showGallery:[],
    defaultGalleryList:[],
    indexLightBox:null,
    //end CoolLightBox
    //carousel
    currentPage: 0,
    isEnableForward: true,
    elPerPage:4,
    //end carousel
  }),
  mounted() {
    this.carouselElPerPage();
  },
  created() {
    //пауза на подумать подождать props, что бы не проверять условие в функции дважды
    setTimeout(()=>this.createGallery(),500)
  },
  computed:{

    ...mapGetters([
      "getProfileInfo",
      "getColorFrame"
    ]),
  },
  methods:{
    createGallery(){
      if(this.info.galleryList != undefined && this.info.galleryList.length > 0){
        this.showGallery.splice(0,this.showGallery.length)
        this.showGallery = this.info.galleryList;
        this.infoLoaded = true;
      }else{
        this.defaultGalleryCreate()
        this.infoLoaded = true;
      }
    },
    defaultGalleryCreate(){
      this.showGallery.splice(0,this.showGallery.length)
      let num = 0;
      for(let i=1;i<=this.pictures_count;i++){
        num = i < 10 ? '0' + i : i;
        this.defaultGalleryList.push('https://202702.selcdn.ru/zakaz/d/'+this.getProfileInfo.playhouse.path+'/show/'+this.id+'/big/'+num+'.jpg')
      }
      this.showGallery = this.defaultGalleryList;
    },
    onImgLoadError(i){
      this.info.galleryList.splice(i,1)
      this.infoLoaded = this.showGallery.length > 0 ? true : false;
      this.$forceUpdate();
    },
    //carousel
    carouselElPerPage(){
      switch(true){
        case (this.width <= 450):
          this.elPerPage = 1;
          break;
        case ((this.width >= 451) && (this.width <= 768)):
          this.elPerPage = 2;
          // console.log('мобилка ' + this.width);
          break;
        case ((this.width >= 768) && (this.width <= 990)):
          this.elPerPage = 3;
          // console.log('планшет ' + this.width);
          break;
        default:
          this.elPerPage = 4;
          // console.log('непонятно ' + this.width);
      }
    },
    handlePageChange(currentPage) {
      this.currentPage = currentPage
      this.isEnableForward = this.$refs.carousel.canAdvanceForward
    },
    onChangeNavigation(direction) {
      this.$refs.carousel.handleNavigation(direction)
    },
    //carousel
  },
}
</script>

<style scoped>
/*  */
/*  */
/*  */
.carousel {
  background: #EEE;
}

.carousel-cell {
  width: 28%;
  height: 200px;
  margin-right: 10px;
  background: #8C8;
  border-radius: 5px;
  counter-increment: carousel-cell;
}

.carousel-cell.is-selected {
  background: #ED2;
}

/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  content: counter(carousel-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}
.carouselGallery img{
  max-width: 100%;
  max-height: 200px;
  box-shadow: 0 0 10px 0 #000;
  border-radius: 5px;
}
.carouselGallery .carousel-arrow{
  width: 32px;
  height:32px;
  text-align: center;
  /*background: rgb(255, 207, 159) !important;*/
  background: transparent;
  border: 1px solid rgb(255, 207, 159) !important;
}
.carouselGallery .carousel-arrow i:before{
  color:rgb(255, 207, 159);
}
.VueCarousel-pagination{}
.carousel-arrow-prev,
.carousel-arrow-next{
  position: absolute;
  top:40%;
  transform:translate(0,-50%);
}
.carousel-arrow-prev{
  left:0;
}
.carousel-arrow-next{
  right: 0;
}
.carousel-arrow[disabled="disabled"]{
  filter: opacity(0.5);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
  padding: 0;
}
@media(max-width: 768px){
  .carousel-arrow-prev,
  .carousel-arrow-next{
    position: absolute;
    top:30%;
    transform:translate(0,-50%);
  }
  .carousel-arrow-prev{
    left:-20px;
  }
  .carousel-arrow-next{
    right:-20px;
  }

}
</style>