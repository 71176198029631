<template src="./middleContent.html"></template>

<script>
import ShowDetailText from './ShowDetailText.vue';
import {mapGetters} from "vuex";
export default {
  components:{ShowDetailText},
  name: "middleContent",
  props: ["info"],
  data: () =>({
    loaded:true,
    mobileMore:false,
    mobilSheet: false,
    indexLightBox:null,
    defaultLogo: "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/show/0/a.jpg",
  }),
  created() {
    if(this.info.persons){
      this.personsCount(this.info.persons);
    }
    // console.log("Первая кирпичик потроган")
  },
  mounted() {
    if(this.getColorFrame.detailTextLink){
      setTimeout(()=>{
        let content = document.getElementById("middleContentText")
        console.warn("EEE")
        console.warn(content)
        let aLinks = content.querySelectorAll("a")
        aLinks.forEach(el=>{
          el.style.color = this.getColorFrame.detailTextLink;
        })
      },200)
    }
  },
  computed:{

    ...mapGetters([
      "getColorFrame"
    ])
  },
  methods:{
    onImgLoadError(e){
      console.log(e);
      this.info.showAfishaImg = this.defaultLogo;
      this.$forceUpdate();
    },
    personsCount(data){
      var roleCount = [{"roleRUS":"Актеры","role":"actor","personCount":0,"person":[]}];
      data.forEach((item)=>{
        if(roleCount.find(el => el.role != item.role)){
          let RoleItem;
          switch (item.role){
            case "playAuthor":
              RoleItem = "Автор оригинала";
              break;
            case "conductor":
              RoleItem = "Дирижер";
              break;
            case "director":
              RoleItem = "Режиссёр";
              break;
            case "choreographer":
              RoleItem = "Хореограф";
              break;
            case "artisticDirector":
              RoleItem = "Художественный руководитель";
              break;
            default:
              //  TODO посмотреть как заполняются поля, что бы получить Русские версии role
              RoleItem = item.role;
          }
          roleCount.unshift({"roleRUS":RoleItem,"role":item.role,"personCount":0,"person":[]})
        }
        let findRoleEl = roleCount.find(el=>el.role == item.role);
        findRoleEl.person.push(item.name);
      })
      roleCount.forEach((item,index,object)=>{
        if(item.person.length === 0){
          object.splice(index, 1);
        }else{
          item.personCount = item.person.length;
        }
      })
      this.info.personsArr = roleCount;
      this.info.personsRole = roleCount.length;
    },
  }
}
</script>
<!--            iShow.time-->
<!--            iShow.age-->
<!--            iShow.loaded-->
<!--            showAfishaImg-->
<!--            iPushka.name-->
<!--            iPushka.genre-->
<!--            iPushka.isFree-->
<!--            iPushka.price-->
<!--            iPushka.maxPrice-->
<!--            iPushka.isPushkinsCard-->
<!--            iPushka.shortDescription-->
<!--            iPushka.description-->
<!--            iPushka.personsArr-->
<style scoped>
@media(max-width: 768px){
  .left-aside{
    width: 100%;
    flex: 0 0 100%;
  }
  .right-column{
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%;
  }
}
@media(min-width: 769px){
  .left-aside{
    max-width: 280px;
  }
  .right-column{
    max-width: calc(100% - 280px);
  }
}
@media(min-width: 990px){
  .left-aside{
    max-width: 400px;
  }
  .right-column{
    max-width: calc(100% - 400px);
  }
}
</style>
