<template src="./timetableContent.html"></template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "timetableContent",
  props: ["info"],
  data:()=>({
    timeConvert: {
      flag:{
        timeZoneM:{ text: "Утренний", f:false},
        timeZoneD:{ text: "Дневной",  f:false},
        timeZoneE:{ text: "Вечерний", f:false}
      },
      headers:[
        {
          text: 'Дата',
          value: 'timeFull',
          align: 'center',
          sortable: false
        }
      ],
      eventsSelect:[{id:0}],
      events:[],
      premier:'',
      mainpushka: {startEvent:'',endEvent:''}
    },
    flags:{
      isLocalAuth:false,
      eidExqlude:false,
      defaultHidden:false,
    },
    texts:["Показать","Скрыть","Расписание сеансов","Сенсы в другое время"]
  }),
  computed:{

    ...mapGetters([
      "getColorFrame"
    ])
  },
  created() {
    if(this.info.eidExqlude){
      this.flags.eidExqlude = this.info.eidExqlude;
      this.flags.defaultHidden = this.info.eidExqlude;
    }
    // console.log("this.$route.params.eid:"+this.$route.params.eid)
    if(this.info.eventTimes){
      this.eventTableConvert(this.info.eventTimes)
    }
  },
  methods:{
    colorCheck(e,check){
      if(check){
        e.target.style = "background:"+this.getColorFrame.detailTableBgHover
      }else{
        e.target.style = ""
      }
    },
    // onColor(free_seat) {
    //   let color = free_seat<1?this.getColorFrame.detailTableBuy:''
    //   return color
    // },
    eventTableConvert(dataEvents){
      dataEvents.forEach((item)=>{
        if(item.id == this.$route.params.eid){
          return;
        }
        let hourConv = item.timedate_h.time.substr(0, 2);
        let timeZoneM = '';
        let timeZoneD = '';
        let timeZoneE = '';
        let timeFull = item.timedate_h.day + ' ' + item.timedate_h.month_r + ' (' + item.timedate_h.day_name_s + ')';
        let inApple = false;
        let startPrice =  item.prices.split('/')[0];

        switch(true){
          case (hourConv < 12):
            timeZoneM = {
              "timeZone": "M",
              "id": item.id,
              "show_id": item.show_id,
              "month_r": item.timedate_h.month_r,
              "time": item.timedate_h.time,
              "day": item.timedate_h.day,
              "day_name_s": item.timedate_h.day_name_s,
              "actual": item.timedate_h.actual,
              "prices": startPrice,
              "status": item.status,
              "pushka": item.pushka,
              "free_seat": item.free_seat
            };
            this.timeConvert.flag.timeZoneM.f = true;
            break;
          case (hourConv >= 12 && hourConv < 17):
            timeZoneD = {
              "timeZone": "D",
              "id": item.id,
              "show_id": item.show_id,
              "month_r": item.timedate_h.month_r,
              "time": item.timedate_h.time,
              "day": item.timedate_h.day,
              "day_name_s": item.timedate_h.day_name_s,
              "actual": item.timedate_h.actual,
              "prices": startPrice,
              "status": item.status,
              "pushka": item.pushka,
              "free_seat": item.free_seat
            };
            this.timeConvert.flag.timeZoneD.f = true;
            break;
          case (hourConv >= 17 && hourConv < 24):
            timeZoneE = {
              "timeZone": "E",
              "id": item.id,
              "show_id": item.show_id,
              "month_r": item.timedate_h.month_r,
              "time": item.timedate_h.time,
              "day": item.timedate_h.day,
              "day_name_s": item.timedate_h.day_name_s,
              "actual": item.timedate_h.actual,
              "prices": startPrice,
              "status": item.status,
              "pushka": item.pushka,
              "free_seat": item.free_seat
            };
            this.timeConvert.flag.timeZoneE.f = true;
            break;
          default:
        }
        this.timeConvert.events.forEach((item)=>{
          if(timeFull === item.timeFull){
            // console.log("В яблочко")
            if(timeZoneM){
              item.timeZoneM = timeZoneM;
            }
            if(timeZoneD){
              item.timeZoneD = timeZoneD;
            }
            if(timeZoneE){
              item.timeZoneE = timeZoneE;
            }
            inApple = true;
          }
        })
        if(inApple){return;}
        let eventTable = {
          timeFull:timeFull,
          timeZoneM: timeZoneM,
          timeZoneD:timeZoneD,
          timeZoneE:  timeZoneE
        }
        this.timeConvert.events.push(eventTable);
      })
      this.timeConvert.headers = [{text: 'Дата', value: 'timeFull', align: 'center', sortable: false}];
      for (const [key, value] of Object.entries(this.timeConvert.flag)) {
        if (value.f){
          this.timeConvert.headers.push({text: value.text, value: key, align: 'center', sortable: false})
        }
        else{
          // тот еще способ сократить количество столбцов в таблие ><
          this.timeConvert.events.forEach(event=>{
            delete event[key];
          })
        }
      }
    },
    itemSelect(e,id){
      this.timeConvert.eventsSelect = [];
      this.timeConvert.eventsSelect.push(e);
      this.$emit('onEventPush',id);
    },
    onEventClick(event_id){

      //console.log(e);

      // if (!"iframe"){
      //   if(this.flags.eidExqlude){
      //     let url = "/e/"+this.$route.params.tid+"/"+"/"+this.$route.params.sid+"/"+event_id;
      //     this.$router.push(url);
      //     this.$router.go();
      //   }else{
      //     this.timeConvert.eventsSelect = [];
      //     this.timeConvert.eventsSelect.push(e);
      //     this.$emit('onEventPush',event_id);
      //   }
      // }

      // if (iframe){}
      this.$emit('onEventPick',event_id);



    },
  }
}
</script>

<style scoped>

</style>
