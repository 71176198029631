const gee = {


    // получить правильное окончание для численных слов
    // билета, билетов, билет
    getWordQuantityEnding(word, count) {

        // TODO но 111 билетов - логика как и с 11, надо просчитывать тоже
        // тогда и case переводить в digit
        // правда маловероятно, что кто-то в зрительском доберется до таких цифр

        let s_count = count.toString();
        // console.log(s_count);
        let last = s_count.substring(s_count.length - 1);
        // console.log(last);

        let ending = "";

        switch (last) {

            case "1":
                ending = "";
                break;

            case "2":
            case "3":
            case "4":
                ending = "a";
                break;

            case "5":
            case "6":
            case "7":
            case "8":
            case "9":
            case "0":
                ending = "ов";
                break;
        }

        if ((count > 10) && (count < 15)) {
            ending = "ов";
        }

        return word + ending;

    },

    sussPaymentStatus(status_id) {
        //console.log(status_id)

        let res = {

            status: parseInt(status_id),
            seller: "none",
            seller_ru: "не указан",
            method: "none",
            method_ru: "не определен",

            f: {
                created: false,
                checked: false,
                done: false,
                returned: false,
                bad: false,
            },

            loaded: false
        };

        switch (parseInt(status_id)) {

            case 50:
            case 77:
                res.f.created = true;
                break;

            case 51:
                res.f.checked = true;
                break;

            case 56:
                res.f.done = true;
                res.seller = 'inet';
                res.seller_ru = 'Интернет';
                res.method = 'bank';
                res.method_ru = 'Карта';
                break;

            case 78:
                res.f.done = true;
                res.seller = 'kassa';
                res.seller_ru = 'Касса';
                res.method = 'card';
                res.method_ru = 'Карта';
                break;

            case 79:
                res.f.done = true;
                res.seller = 'kassa';
                res.seller_ru = 'Касса';
                res.method = 'cash';
                res.method_ru = 'Наличные';
                break;

            case 89:
                res.f.done = true;
                res.seller = 'kassa';
                res.seller_ru = 'Касса';
                res.method = 'bill';
                res.method_ru = 'Счет';
                break;

            case 103:
                res.f.done = true;
                res.seller = 'external';
                res.seller_ru = 'Внешний';
                res.method = 'bill';
                res.method_ru = 'Счет';
                break;

            case 57:
            case 59:
            case 104:

                res.f.bad = true;
                res.seller = 'inet';
                res.method = 'bank';
                break;

            default:
                res.f.bad = true;
        }

        res.loaded = true;

        return res;
    }
}

export default gee
