<template src="./PaymentUserOrder.html"></template>

<script>


import {mapGetters} from "vuex";

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import gee                from "@/utils/gee";

export default {
  name: "PaymentUserOrder",
  components:{VuePhoneNumberInput},

  props:{

    title: String,

  },


  data: () => ({
    colors: { //TODO COLOR SETTINGS TO STORE
      body: {
        bg:"#fff",//#2e342b
        bgAlt:"#d2d2d2",//#2e342b
        text:"#575a67",
        btn:"#ea2425",
        btnAlternative:"#aa00ad",
        border:"#575a67",
        bgOrder:"#38393d"
      },
    },

    gee: gee,

    phone_test: null,

    check:{
      family: false,
      name:   false,
      fname:  false,

      fio:    false,

      phone:  false,
      email:  false,

      rules_main: false,
      rules_pushka: false,

      allGood: false,

      message: "Необходимо заполнить поля",
      message_type: "info",
    },

    user:{

      payment_type:0,

      family: "",
      name: "",
      fname: "",

      phone: "",
      email: "",

      isGood: false

    },

    // TODO грузить из конфига
    coupon: {
      code: "",
      alert_message: "Укажите купон или промо-код, если есть",
      alert_type: "info",
      isValid: false,
      isTouch: false,
      isLoading: false,
    },




    loaded: true,

  }),

  computed:{

    ...mapGetters([
      "getUserCart",
      "getDiscounts",
      "getOrderIframeResult",
      "getProfileInfo",
      "getColorFrame"
    ]),



  },

  watch: {

    // следим за получением ответа от api
    getOrderIframeResult() {

      //console.log(this.getOrderIframeResult);

      // запрос на создание заказа вернулся
      if (this.getOrderIframeResult.ready) {

        // если хороший - делаем переход в банк
        // пишем куку по smart_link?
        if (this.getOrderIframeResult.good) {

          console.log('goto bank');
          window.location.href = this.getOrderIframeResult.bank_link;

        }
        // если он плохой - показываем snackbar
        else {
          console.warn('bad order');
        }
      }

    },

  },


  methods:{

    checkPromo(){
      console.log('check promo');
      console.log(this.getUserCart);
      console.log(this.getUserCart.event.id);
      console.log(this.getDiscounts.discounts);
      console.log(this.coupon.code);

      this.coupon.isLoading = true;
      this.coupon.alert_message = "Проверяем промокод";
      this.coupon.alert_type = "warning"

      // очистим скидки
      this.$store.commit('clearDiscount');

      // запрос на сервер
      this.$store.state.api.checkUniPromoCode(
        this.getUserCart.event.id,
        this.coupon.code,
        this.getDiscounts.discounts
      )
        .then(data=>{
          console.log(data);

          // не нашли
          if (!data.b){
            this.coupon.alert_message = data.m;
            this.coupon.alert_type = "error"
            this.coupon.isLoading = false;
          }
          // да, есть такой
          else{
            // единственный купон
            if (data.c == 10){
              console.log(data.d);
              this.coupon.alert_message = data.m;
              this.coupon.alert_type = "success"
              this.coupon.isLoading = false;

              console.log(this.getDiscounts.discounts);
              console.log(data.d.id);

              // проверить, что эта скидка есть в массиве разрешенных
              let discount_exist = this.getDiscounts.discounts.find(x=>x == data.d.id);
              //console.log(discount_exist);

              if (discount_exist){
                // начислить скидку
                this.$store.dispatch('setDiscountPrice', data.d);
                // this.coupon.isValid = true;
              }

            }

            // несколько
            if (data.c == 100){
              console.log("many");
              console.log(data.d);
            }



          }

        })





      this.coupon.alert_message = "Проверили - результат - хорошо, скинули стоимость в поля";
      this.coupon.alert_type = "success"
      this.coupon.isLoading = false;

      this.coupon.isTouch = false;
    },

    onPromoChanged(e){
      this.coupon.code = e.toLocaleUpperCase()
      this.coupon.isTouch = true;
    },

    //
    addOrder(){

      //console.warn("Добавлять заказ - но делать это в store");
      //this.$store.commit('addOrder');
      this.$store.dispatch('addIframeOrder');

    },

    // для того чтобы извне можно было сбрость с пушкинской и отключить поле
    // там где нет пушки (если в предыдущем спектакле была выбрана пушка)
    setPaymentType(type){
      this.user.payment_type = type;
      this.$store.commit("setPaymentType", type);
    },

    isEmailValid: function(value) {

      //console.log(value);

      const reg = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

      return ((value === "") && value.length>2)? "" : (reg.test(value));
    },


    resultsExample(e){

      this.check.phone = e.isValid;

      if (e.isValid){
        this.user.phone = e.nationalNumber;
      }
      else{
        this.user.phone = "";
      }

      this.changeUserData();

    },

    changePaymentType(isPushka=false){

      if (isPushka){
        this.user.payment_type  = 30;
        this.check.rules_pushka = false;
      }
      else{
        this.user.payment_type = 0;
      }

      this.changeUserData();

    },

    changeUserData(){

      this.check.family = false;
      this.check.name   = false;
      this.check.fname  = false;
      this.check.fio    = false;
      this.check.phone  = false;

      this.user.fio = '';

      // пушка
      if (this.user.payment_type===30){

        //console.log('30');

        if (this.user.phone.length==10){
          this.check.phone  = true;
        }

        this.check.family   = this.user.family.length > 2;
        this.check.name     = this.user.name.length > 2;
        this.check.fname    = this.user.fname.length > 2;


        this.check.fio = (
            (this.check.family) &&
            (this.check.name)   &&
            (this.check.fname)
        );

        if (this.check.fio){

          this.user.fio =
              this.user.family.trim() + " " +
              this.user.name.trim() + " " +
              this.user.fname.trim();
        }

      }

      // обычная карта
      else {

        this.check.family = true;
        this.check.name   = true;
        this.check.fname  = true;
        this.check.fio    = true;

        this.check.phone  = true;
        this.check.rules_pushka = true;

        this.user.fio = '';

      }


      this.user.email = this.user.email.trim();
      this.check.email = this.isEmailValid(this.user.email);

      //console.log(this.user_data);

      this.check.allGood = (
          (this.check.fio) &&
          (this.check.phone) &&
          (this.check.email) &&
          (this.check.rules_main) &&
          (this.check.rules_pushka)
      );

      this.user.isGood = this.check.allGood;

      if (this.check.allGood){
        this.check.message =  "Форма заполнена, можно приступить к оплате";
        this.check.message_type = "success";
      }
      else{
        this.check.message =  "Необходимо заполнить поля";
        this.check.message_type = "info";
      }

      this.$emit('onChangeCheckGood', this.check.allGood);

      //console.log(this.check.allGood);

      this.$store.commit(
          "setUserCartInfo",
          JSON.parse(JSON.stringify(this.user)));
    },
  }

}
</script>

<style scoped>
.btn-opacity-low.v-btn{
  color: #000 !important;
}
.btn-opacity-low.v-btn:before{
  opacity: 0.4;
  background-color: #ccc;
}
</style>
