<template>
  <v-row no-gutters class="mt-0" >
    <v-col cols="12" sm="12" class="px-0 py-5 pa-sm-5 ma-0">
      <v-card>
        <!--              Промотка до выбора билетов-->
        <v-btn @click="scrollMeTo('choiceTicket')" width="100%" color="blue" dark>
          Купить билеты
        </v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "buyBtnLink",
  methods:{
    scrollMeTo(refName) {
      let element = this.$refs[refName];
      let top = element.offsetTop;
      window.scrollTo({
        top: top,
        left:0,
        behavior: "smooth"});
    },
  }
}
</script>

<style scoped>

</style>