<template src="./topContent.html"></template>

<script>
import { mapGetters }     from "vuex";
export default {
  name: "topContent",
  props: ["info"],
  computed:{

    ...mapGetters([
      "getColorFrame"
    ])
  }
}
</script>

<style scoped>

</style>