<template src="./ShowBuyByTIDaSID.html"></template>

<script>
//Кирпичи страницы:
import galleryContent from '@/components/brick/ShowBuy/byTIDaSID/galleryContent'
import middleContent from '@/components/brick/ShowBuy/byTIDaSID/middleContent'
import buyBtnLink from '@/components/brick/ShowBuy/byTIDaSID/middleContent/buyBtnLink.vue'
import timetableContent from '@/components/brick/ShowBuy/byTIDaSID/timetableContent'
import topContent from '@/components/brick/ShowBuy/byTIDaSID/topContent'
import HallContent from '@/components/brick/ShowBuy/byTIDaSID/HallContent'
import PixiHallContent from "@/components/brick/ShowBuy/byTIDaSID/PixiHallContent";
//компоненты страницы

// TODO узнать куда перенести
import '@jambonn/vue-concise-carousel/dist/vue-concise-carousel.css'

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import {mapGetters} from "vuex";

export default {
  name: "ShowBuyByTIDaSID",

  components: {
    galleryContent,
    buyBtnLink,
    middleContent,
    timetableContent,
    topContent,
    HallContent,
    PixiHallContent
  },
  //props:["info"],

  props:{

    tid:    {type: Number, default: 0},
    sid:    {type: Number, default: 0},
    eid:    {type: Number, default: 0},

    iframe: {type: Boolean, default: false}, // зависит какие ссылки будут

  },

  data: () => ({

    outsideIDs:false,
    in_brickKey:0,
    in_topInfo:{},
    in_middleInfo:{},
    in_Gallery:{},
    in_timeTable:{},

    in_hall:{},
    in_HallKey:0,

    showFull:{
      show:{
        loaded:false,
        info:{
          name: "Спектакль",
          age: "0+",
          events:[],
          time: "длительность неопределена",
          loaded: false,
        },
        data:[]
      },
      iPushka:{
        name:             "Имя",
        isFree:           false,
        price:            "нет цены",
        maxPrice:         "нет максимальной",
        isPushkinsCard:   false,
        shortDescription: "Короткое описание",
        description:      "Описание",
        genre:            "Жанр" ,
        persons:          "Актеры и персонал",
        pushkaGallery:    false,
        galleryList:      [],
        galleryIndex:     null,
        loaded:false,

        personsRole:      0,
        personsArr:[],
      },
    },
    props_data:{
      show_id:    0,
      event_id:   0,
      culture_id: 0,
      theater_id: 0,
    },

    showAfishaImg: "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/show/0/a.jpg",

    order:{
      email:'',
      phone:'',
      fio:'',
      event_id:   0,
      show_id:    0,
      culture_id: 0,
      link: false,
      payment_type: 0,
      seats:[],
      show_name:'Спектакль не выбран',
      total:{
        price:0,
        count:0,
      },
      date: 'Сегодня',
    },
    defaultLogo: "https://202702.selcdn.ru/zakaz/d/E39FFEA32C/show/0/a.jpg",
    theater_folder:["E39FFEA32C","B6700AADEA","A1300C78A0","D81BBC58D4","F4500EBDC8","CE223DFBB5","G0934A7F15","H37ACA7049","J853F18DD3"]
  }),

  computed:{

    ...mapGetters([
      "getProfileInfo",
      "getColorFrame"
    ])

  },

  created() {

    //console.log(this.sid);

    if(this.sid > 0){

      this.props_data.show_id = this.sid;
      this.props_data.theater = this.tid;
      this.loadEventsByShow();

    }

    if(this.sid === undefined){

      console.warn("see in router");
      //if (this.$route.params.tid && this.$route.params.sid){


    }
  },
  mounted() {
    //console.log("ShowBuyByTIDaSID mounted")
    if(this.props_data !== undefined){

      //console.log("ShowBuyByTIDaSID info:")
      //console.log(this.props_data)
      this.outsideIDs = true;
      //this.iframeToLoad(this.info);
    }
    else{
      console.warn('none sid')
    }
  },
  methods:{

    // загрузить инфу о спектакле - init
    loadShow(show_id){

      this.props_data.show_id    = show_id;

      //console.log(this.props_data);

      //this.props_data.theater_id = theater_id;
      this.loadEventsByShow();

    },

    // iframeToLoad(IDs){
    //   this.props_data.show_id    = IDs.sid;
    //   this.props_data.event_id   = IDs.eid;
    //   this.props_data.theater_id = IDs.tid;
    //   this.loadEventsByShow();
    // },

    // id_find(){
    //   // this.id_pack.theater_id = this.$route.params.tid;
    //   // this.id_pack.show_id = this.$route.params.sid;
    //   //
    //   // this.id_pack.theater_id = 2;
    //   // this.id_pack.show_id = 43
    //
    // },
    //Внутренние функции
    keyPush(){//перезапускаем кирпичи
      this.in_brickKey++;
    },

    loadInnerContent(inner,arr,bool,data){//формируем данные для кирпичей
      switch(bool){
        case true:
          arr.forEach((item)=>{
            this[inner][item] = data[item];
          })
          break;
        default:
          this[inner][arr[0]] = data;
      }
    },
    scrollMeTo(refName) {//скролим до кнопок выбора даты
      let element = this.$refs[refName];
      let top = element.offsetTop;
      window.scrollTo({
        top: top,
        left:0,
        behavior: "smooth"});
    },

    urlAfishaAdress(singleImage,arrImage){//формирование карусели
      this.showFull.iPushka.galleryList.push("https://pro.culture.ru/uploads/"+singleImage);
      if(arrImage.length > 0){
        arrImage.forEach(item=>{
          this.showFull.iPushka.galleryList.push('https://pro.culture.ru/uploads/'+item.name);
        })
        this.showFull.iPushka.pushkaGallery = true;
      }else{
        this.showFull.iPushka.pushkaGallery = false;
      }

      let path = "https://202702.selcdn.ru/zakaz/d/";
      //let theater_folder = "E39FFEA32C";
      let theater_folder = this.getProfileInfo.playhouse.path;
      let theater_afisha = "/show/" + this.showFull.show.info.id + "/a.jpg";
      let urlLogo = path + theater_folder + theater_afisha;
      //console.log(urlLogo);
      this.defaultLogo = path + theater_folder + "/show/0/a.jpg";
      switch(true){
        case (this.props_data.culture_id===0):
          this.showAfishaImg = path + theater_folder + "/show/0/a.jpg"
          break;
        default:
          this.showAfishaImg = urlLogo;
          this.showFull.iPushka.galleryList.unshift(urlLogo);
          this.loadInnerContent("in_Gallery",["galleryList"],false,this.showFull.iPushka.galleryList)
      }
      this.loadInnerContent("in_middleInfo",["showAfishaImg"],false,urlLogo);
      this.keyPush();
      return;
    },

    loadEventsByShow(){//show получаем

      this.showFull.show.loaded = false;

      let pr2 = this.$store.state.api.findEventsByShowStatus(this.props_data.show_id, 32, true);
      pr2.then( data =>{
        //console.log(data);
        this.showFull.show = data['0'];
        //console.log("Чекаем что в шоу получилось:")
        //console.log(data[0])



        this.props_data.culture_id = this.showFull.show.info.culture_id;

        this.showFull.show.info.id = parseInt(data['0'].info.id);

        //console.log(data['0'].info);





        this.loadInnerContent("in_topInfo",["id","name","age","culture_id"],true,data['0'].info);

        // надо добавить описание с апи, если оно есть
        if (data['0'].info.description){
          data['0'].info.shortDescription = data['0'].info.description;
          console.log(data['0'].info);
          this.loadInnerContent("in_middleInfo",["time","age","shortDescription"],true,data['0'].info);
          //this.in_middleInfo.shortDescription = "!!!!";
          //console.warn(this.in_middleInfo);
        }
        else{
          this.loadInnerContent("in_middleInfo",["time","age"],true,data['0'].info);
          this.in_middleInfo.shortDescription = undefined;
        }



        this.loadInnerContent("in_timeTable",["eventTimes"],false,data['0'].data);

        this.showFull.show.loaded = true;
        this.keyPush();


        if (this.showFull.show.info.culture_id>0){
          this.loadPushkaByShow();
        }
        // отображаем если не добыли с культуры инфу
        else{
          console.log('no-pushka')
          this.defaultEvent()
        }
      })
    },
    defaultEvent(){
      this.showFull.iPushka.loaded = false;

      this.urlAfishaAdress("",[]);

      this.loadInnerContent("in_Gallery",["galleryList"],false,[])
      //this.loadInnerContent("in_middleInfo",["name","genre","isFree","price","maxPrice","isPushkinsCard","shortDescription","description","personsArr"],true,[])
      this.loadInnerContent("in_middleInfo",["name","genre","isFree","price","maxPrice","isPushkinsCard","description","personsArr"],true,[])
      this.loadInnerContent("in_middleInfo",["persons"],false,[])
      this.loadInnerContent("in_hall",["show_id","event_id","culture_id","theater_id"],true,this.props_data);





      this.keyPush();
    },
    //iPushka получаем
    loadPushkaByShow(){
      // console.log("this.showFull.iPushka.loaded")
      // console.log(this.showFull)
      this.showFull.iPushka.loaded = false;
      // console.log("this.props_data.culture_id")
      // console.log(this.props_data.culture_id)
      let pr2 = this.$store.state.api.loadPushkaPro(this.props_data.culture_id,false);
      pr2.then( data =>{
        console.log("data")
        console.log(data)
        if(data.total>0){
          this.showFull.iPushka = data['events'][0];
          //перемещение по полученой структуре
          this.showFull.iPushka.genre = data['events'][0].extraFields.genre;
          this.showFull.iPushka.persons = data['events'][0].extraFields.persons;
          this.showFull.iPushka.galleryList = [];
          this.showFull.iPushka.loaded = true;

          this.urlAfishaAdress(data['events']['0'].image.name,data['events']['0'].gallery);

          this.loadInnerContent("in_Gallery",["galleryList"],false,this.showFull.iPushka.galleryList)
          this.loadInnerContent("in_middleInfo",["name","genre","isFree","price","maxPrice","isPushkinsCard","shortDescription","description","personsArr"],true,data['events'][0])
          this.loadInnerContent("in_middleInfo",["persons"],false,data['events'][0].extraFields.persons)

          this.showFull.iPushka.loaded = true;
          this.loadInnerContent("in_hall",["show_id","event_id","culture_id","theater_id"],true,this.props_data);

          this.keyPush();
        }else{
          console.log('pushka-yes, but no data')
          this.defaultEvent()
        }
      })

    },

  // Hall Data into
    onEventToShowHall(event_id){
      if(event_id !== undefined){
        this.props_data.event_id = event_id;
      }
      this.$refs.in_hallPortal.id_stak(this.props_data);
    },

    onEventPick(event_id){
      this.$emit('onEventPick',event_id);
    },
    // galleryCount(){
    //   console.log(this.showFull.show.info.pictures_count)
    //   return this.showFull.show.info.pictures_count;
    // }
  }
}



</script>

<style scoped>
  @media(max-width: 768px){

    .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row{
      min-height: auto !important;
    }
    .v-data-table__mobile-table-row .v-data-table__mobile-row:last-child .v-data-table__mobile-row__cell{
      margin-bottom: 0 !important;
    }
  }
  @media(min-width: 769px){

    .fullWidthColumn{
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  @media(min-width: 990px){

    .fullWidthColumn{
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
</style>

