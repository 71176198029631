<template>
  <v-col>
    <div v-if="description" class="description">
      <span v-html="description"></span>
    </div>
    <!--список участников-->
    <div v-for="role in personsArr" :key="role.name">
      <div class="my-5">
        <b>{{role.roleRUS}}</b>:
        <span v-for="(name,i) in role.person" :key='"actor_"+i'>
            {{name}}<span v-if="i != role.personCount-1">, </span>
        </span>
      </div>
    </div>
  </v-col>
</template>

<script>

export default {
  name: "ShowDetailText",
  props: ['description','personsArr'],
  created() {
    //console.log("Второй кирпичик потроган")
  }
}
</script>

<style scoped>

</style>
