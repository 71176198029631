<template src="./pixiHall.html"></template>

<script>
import pixiEditorHall from "./pixiEditorHall.vue"
// import _ from "lodash";
import {mapGetters} from "vuex";

export default {
  name: "PixiDev",
  components: {pixiEditorHall},
  props:{
    showCaptionInMobile: {type: Boolean, default:false},
  },
  data: () => ({
    flags:{
      hallShow:false,
      idsLoaded: false,
      showheader: "Начинаем",
      eventloaded:false,
      pixiHallReady:false //Включать событием
    },

    real_hall:{},
    hallReady:false,
    id_pack:{
      show_id:    0,
      event_id:   696,
      culture_id: 0,
      theater:    0,
      mo_id:      0,
    },
    seatRaw:{},

    emptyHallConfig:{
      base:{
        type: 'user',
        mode: 'event',
        view: 'sale',
        event_id: 0,
      },
      options:{
        zone:true,
        discount:false,
        armour:false,
        puskin:false

      },
      additional:{
        zone_n:0,

        monger_mode: "show_all",
        monger_id:0,
        seat_status_id:0
      }
    }, //hallConfig
    hallConfig:{
      base:{
        type: 'user',
        mode: 'event',
        view: 'sale',
        event_id: 0,
      },
      options:{
        zone:true,
        discount:false,
        armour:false,
        puskin:false

      },
      additional:{
        zone_n:0,

        monger_mode: "show_all",
        monger_id:0,
        seat_status_id:0
      }
    }, //hallConfig
  }),
  computed:{
    ...mapGetters([
        "getColorZones",
      "getColorFrame"
    ])
    //TODO передать или вывести если есть в PixiHall получаемые данные о цветовых зонах, сейчас там заглушка
  },
  created() {
    // this.loadRealHallFull()
  },
  methods:{

    id_stak(IDs){
      console.warn("Проверка передачи IDS")
      // console.warn("IDs")
      // console.warn(IDs)
      this.flags.idsLoaded = false;
      this.HallConfig = Object.assign({}, this.emptyHallConfig);
      this.id_pack = Object.assign({}, IDs);
      this.flags.idsLoaded = true;//TODO хз
      if(this.id_pack.event_id !== 0){
        this.flags.showheader = "Подключаемся";
      //   this.loadSeatsData();
        console.warn("Запускаем обычск зала")
        if(this.hallReady)this.$refs.pixiEditorHall.reSelectEvent()
        this.loadRealHallFull()
      }else{
        this.flags.showheader = "Данный сеанс не обнаружен";
      }
    },

    startHallAction(){
      console.warn("this.real_hall")
      console.warn(this.real_hall)
      this.$refs.pixiEditorHall.stepOne_sizeCanvas(this.real_hall,this.seatRaw)
      this.$refs.pixiEditorHall.startToPrint("pre")
      this.$refs.pixiEditorHall.startToPrint("reCreate")
    },

    loadRealHallFull(){
      this.flags.pixiHallReady = false;
      this.flags.eventloaded = false;
      console.warn("Обыскиваем новый зал")
      let pr = this.$store.state.api.getEventInet(this.id_pack.event_id, this.id_pack.mo_id);

      pr.then( data =>{
        console.warn("loadRealHallFull")
        console.warn(data)
        this.seatRaw.b = true;
        this.seatRaw.data = Object.assign({},data);
        this.seatRaw.getPricesColors = this.getPricesColors(data.prices,data.event.prices,data.price_map.zone_count);
        this.flags.pixiHallReady = true;
        this.loadRealHall()
      })
    },
    loadRealHall(){
      // fetch('https://api.zakaz.cloud/layout/28?key=KJLHDLKF09ulkdst&version=2')
      fetch('/new-edit-hall.json')
      .then(response => response.json())
      // .then(response => response)
      .then( (data) =>{

        // console.warn("loadRealHall")
        // console.warn(data)

        if(!data.layout) return;

        let customHall = {
          layout:data.layout,
          seat_positions:[]
        }

        customHall.layout.bg = {
          image: "/hall.jpg",
          size: [2441,1080],
          path: "realHall"
        }

        this.seatRaw.data.seats.forEach(el=>{
          let foundModChair = data.seat_positions.find(item=>item.id === el.id)
          if(!foundModChair) return;
          if(!el.name)el.name = "chair";
          if(!el.graphic)el.graphic = foundModChair.graphic;
          if(!el.coords)el.coords = foundModChair.coords;
        })

        customHall.seat_positions = this.seatRaw.data.seats
        this.real_hall = Object.assign({},customHall)

        //услованая проверка флага готовности

        if(this.hallReady)this.$refs.pixiEditorHall.dataHallRecord(this.real_hall,this.seatRaw)
        if(this.hallReady)this.$refs.pixiEditorHall.startToPrint("reCreate");
        this.hallReady = true;
        // this.createFilters()
      })
    },

    seatToOrder(seats, last_seat){
      console.log(seats, last_seat)
      this.$store.commit(
          'setSeatsData',
          JSON.parse(JSON.stringify(seats))
      );
      this.$store.commit(
          'createSeatInfo',
          this.seatRaw.data.seats
      );

      if (!(last_seat==undefined)){
        if (last_seat.selected){
          this.$emit('onSeatSelected', last_seat.id);
        }
      }

    },

    onChairProblem(id){
      let found = this.real_hall.seat_positions.find(el=>el.id === id)
      if(!found) return;
      found.spriteError = true;
    },
    onShowLoader(){
      this.flags.hallShow = false;
    },
    onReadyToShow(){
      this.flags.hallShow = true;
    },
    reLoad(){
      console.log("reLoad")
      this.flags.hallShow = false;
      if(this.hallReady)this.$refs.pixiEditorHall.startToPrint("reCreate");
    },
    reCenter(){
      console.log("reCenter")
      if(this.hallReady)this.$refs.pixiEditorHall.containerToCenter();
    },
    updateMode(id){
      console.log("updateMode " + id)
      if(this.hallReady)this.$refs.pixiEditorHall.seatColorZone(id);
    },
    getPricesColors(dataPrices,dataPrice,dataZoneCount){
      let price = [];
      let prices = dataPrice.split('/');
      for (let i = 0; i<dataZoneCount; i++){
        let t = {};
        t.id = dataPrices.find(el => el.price_s === prices[i]).zone;
        t.price = prices[i];
        t.color = this.getColorZones[i+1];
        price.push(t);
      }
      return price;
    },
    hallReadyFlag(flag){
      this.hallReady = flag;
    },
    eventloadedFlag(data){//TODO проверить надобность флага
      this.flags.eventloaded = data;
      // прокидываем выше событие
      this.$emit('onEventLoad',true);

    },
  },
}
</script>

<style scoped>
  .loader-gif{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    max-width: 200px;
    z-index: 2225;
  }
</style>